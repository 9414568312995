<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统日志</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">日志信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="操作人员" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 日志列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span
            style="
              display: -moz-inline-box;
              display: inline-block;
              width: 120px;
            "
            >系统日志列表</span
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="操作人" prop="userName"></el-table-column>
        <el-table-column label="ip地址" prop="ipaddr"></el-table-column>
        <el-table-column label="设备信息" prop="hardware"></el-table-column>
        <el-table-column
          label="操作时间"
          prop="operatingTime"
        ></el-table-column>
        <el-table-column
          label="操作内容"
          prop="operatingContent"
        ></el-table-column>
        <el-table-column
          label="操作详情"
          prop="operatingDetail"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getSysSchoolList } from '@/http/api'
export default {
  data () {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取学校列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getSysSchoolList(this.queryForm).then((res) => {
        console.log('getSysSchoolList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    }
  }
}
</script>

<style>
</style>
